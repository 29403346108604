import Cleave from 'cleave.js';
export const CleaveOptions = {
  numeral: {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
    numeralDecimalMark: ',',
    delimiter: '.',
    numeralPositiveOnly: true,
  },
  numeral_9_digits_2_decimals: {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
    numeralDecimalMark: ',',
    delimiter: '.',
    numeralPositiveOnly: true,
    numeralIntegerScale: 9,
    numeralDecimalScale: 2,
  },
  numeral_6_digits_2_decimals: {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
    numeralDecimalMark: ',',
    delimiter: '.',
    numeralPositiveOnly: true,
    numeralIntegerScale: 6,
    numeralDecimalScale: 2,
  },
  numeral_3_digits_2_decimals: {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
    numeralDecimalMark: ',',
    delimiter: '.',
    numeralPositiveOnly: true,
    numeralIntegerScale: 3,
    numeralDecimalScale: 2,
  },
  numeral_2_digits_2_decimals: {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
    numeralDecimalMark: ',',
    delimiter: '.',
    numeralPositiveOnly: true,
    numeralIntegerScale: 2,
    numeralDecimalScale: 2,
  },
  numeral_1_digit_3_decimals: {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
    numeralDecimalMark: ',',
    delimiter: '.',
    numeralPositiveOnly: true,
    numeralIntegerScale: 1,
    numeralDecimalScale: 3,
  },
  numeral_5_digits: {
    numeral: true,
    stripLeadingZeroes: false,
    numeralDecimalMark: ',',
    delimiter: '',
    numeralPositiveOnly: true,
    numeralIntegerScale: 5,
    numeralDecimalScale: 0,
  },
  numeral_7_digits: {
    numeral: true,
    numeralDecimalMark: ',',
    delimiter: '',
    numeralPositiveOnly: true,
    numeralIntegerScale: 7,
    numeralDecimalScale: 0,
  },
};
export default (app) => {
  app.directive('literal-cleave', {
    mounted: (el, binding, vnode) => {
      let inputEl = el.querySelector('md-filled-text-field');
      inputEl.cleave = new Cleave(
        inputEl,
        CleaveOptions[binding.value.type] || {},
      );
    },
    updated: (el) => {
      let inputEl = el.querySelector('md-filled-text-field');
      setTimeout(function () {
        inputEl.value = inputEl.cleave.properties.result;
      }, 100);
    },
  });
};
