import { createApp } from 'vue';
import CalculatorWrap from './ui/components/3-organisms/Calculator/CalculatorWrap.vue';
import initCleaves from '@/ui/directives/cleave';
import { createPinia } from 'pinia';

const pinia = createPinia();

const allCalculators = document.getElementsByClassName('m-calculator');
for (let i = 0; i < allCalculators.length; i++) {
  let mountElementDataset = allCalculators[i].dataset;
  const app = createApp(CalculatorWrap, {
    config: mountElementDataset,
    component_id: i,
  });
  initCleaves(app);

  window.addEventListener('pageshow', function () {
    app.use(pinia).mount(allCalculators[i]);
  });
}
