<template>
  <Calculator
    :width="config.size"
    :theme="config.theme"
    :productTypes="JSON.parse(config.productTypes)"
    :customerOptions="config.customerOptions"
    :disableVouchers="config.disableVouchers"
    :onlyThisPage="config.onlyThisPage"
    :promotionCode="config.promotionCode"
    :component_id="config.component_id"
  />
</template>

<script>
import Calculator from './Calculator.vue';
export default {
  name: 'CalculatorWrap',
  components: {
    Calculator,
  },
  props: {
    config: {
      type: Object,
    },
  },
};
</script>
